import React, { useState, useEffect } from 'react';

import Company from './Company';
import home from './home.jpg';
import park from '../../assests/Home/park.png';
import LazyImage from '../../Components/Lazy/LazyImage';

const Intro = () => {
  const [parkImgSrc, setParkImgSrc] = useState(null);

  useEffect(() => {
    setParkImgSrc(park);
  }, []);

  return (
    <div className="pt-48" id="intro">
      <div className="bg-black">
        <img src={home} alt="home" className="w-full" />
      </div>
      <Company />
      <div className="">
        {parkImgSrc && (
          <LazyImage src={parkImgSrc} alt="parkimg" className="w-full" />
        )}
      </div>
    </div>
  );
};

export default Intro;
