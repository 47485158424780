import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import Modal from '../../../Components/Model/Modal';
import LoaderSmall from '../../../Components/Loader/LoaderSmall';
import { baseUrl } from '../../../Redux/baseUrl';

const CarDetailsModal = ({ isOpen, onClose, car }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleBuyOrBookClick = () => {
    setContactModalOpen(true);
  };

  const handleContactModalClose = () => {
    setContactModalOpen(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="p-6 bg-white rounded-lg shadow-xl font-poetsen md:max-w-screen-md">
          <Carousel showThumbs={false}>
            {[0, 1, 2, 3, 4].map((index) => (
              <div key={index}>
                {loading && <LoaderSmall />}
                <img
                  src={`${baseUrl}api/v1/cars/${car.id}/images/${index}`}
                  alt={`${car.name} Slide ${index}`}
                  className="w-[90%] h-64 object-cover object-center rounded-t-lg mb-4"
                  onLoad={handleImageLoad}
                />
              </div>
            ))}
          </Carousel>
          <h2 className="text-3xl font-semibold mb-2">{car.carname}</h2>
          <p className="text-gray-700 mb-2 text-lg font-medium">
            ₹ {car.price}
          </p>
          <div className="grid grid-cols-2 gap-2 text-gray-600 text-sm">
            <div className="flex justify-between items-center bg-gray-100 p-2 rounded-md">
              <span className="font-medium">Model:</span>
              <span>{car.model}</span>
            </div>
            <div className="flex justify-between items-center bg-gray-100 p-2 rounded-md">
              <span className="font-medium">Kms:</span>
              <span>{car.kms}</span>
            </div>
            <div className="flex justify-between items-center bg-gray-100 p-2 rounded-md">
              <span className="font-medium">Variant:</span>
              <span>{car.variant}</span>
            </div>
          </div>
          <div className="flex items-center justify-around pt-5">
            <button
              onClick={handleBuyOrBookClick}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2 transition duration-200 ease-in-out transform hover:scale-105"
            >
              Book
            </button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={contactModalOpen} onClose={handleContactModalClose}>
        <div className="p-6 bg-white rounded-lg shadow-xl font-poetsen md:max-w-screen-md text-center flex flex-col items-center justify-center">
          <p>Our team will contact you soon.</p>
          <p className="text-red">Or</p>
          <p>Contact: +91 9047120120</p>
        </div>
      </Modal>
    </>
  );
};

export default CarDetailsModal;
