import { configureStore } from '@reduxjs/toolkit';

import signupSlice from '../Redux/Users/signupSlice';
import loginSlice from './Users/loginSlice';
import getUserSlice from './Users/getUserSlice';
import carSlice from './Cars/carSlice';
import createCarSlice from './Cars/createCarSlice';

const store = configureStore({
  reducer: {
    signup: signupSlice,
    login: loginSlice,
    getUser: getUserSlice,
    cars: carSlice,
    createCar: createCarSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
