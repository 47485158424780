import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { login, selectMessage } from '../../../Redux/Users/loginSlice';
import LoaderSmall from '../../../Components/Loader/LoaderSmall';
import { AuthContext } from '../../../Shared/context/Auth-Context';

const LoginForm = ({ onCloseModal, onLoginFailure, setPopup }) => {
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const message = useSelector(selectMessage);
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    if (message) {
      setIsLoading(false);
      if (message === 'Login successful') {
        setPopup({ show: true, message, type: 'success' });
        auth.login();
        onCloseModal();
      } else {
        setPopup({
          show: true,
          message: 'Login failed. Please check Email and Password.',
          type: 'fail',
        });
      }
    }
  }, [message, auth, onCloseModal, setPopup]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(login(userData)).then((response) => {
      setIsLoading(false);
      if (response.error) {
        setPopup({
          show: true,
          message: 'Login failed. Please check Email and Password.',
          type: 'fail',
        });
        onLoginFailure();
      } else {
        setPopup({ show: true, message: 'Login successful', type: 'success' });
        auth.login();
        onCloseModal();
      }
    });
  };

  return (
    <>
      {isLoading && <LoaderSmall asOverlay />}
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            value={userData.email}
            onChange={(e) =>
              setUserData({ ...userData, email: e.target.value })
            }
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <input
            type="password"
            value={userData.password}
            onChange={(e) =>
              setUserData({ ...userData, password: e.target.value })
            }
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <button
          type="submit"
          className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Login
        </button>
      </form>
    </>
  );
};

export default LoginForm;
