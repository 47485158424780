import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { baseUrl } from '../baseUrl';

export const login = createAsyncThunk(
  'login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${baseUrl}api/v1/users/login`,
        {
          email,
          password,
        },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const { userId, email: userEmail, token } = response.data;
      localStorage.setItem('userId', userId);
      localStorage.setItem('token', token);

      return { userId, email: userEmail };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    userId: localStorage.getItem('userId') || null,
    status: 'idle',
    error: null,
    message: null,
    lastLogout: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.userId = action.payload.userId;
    },
    logout: (state, action) => {
      localStorage.removeItem('userId');
      localStorage.removeItem('token');
      localStorage.removeItem('isLoggedIn');
      state.userId = null;
      state.lastLogout = action.payload;
    },
    resetMessage: (state) => {
      state.message = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = 'loading';
        state.message = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = 'idle';
        state.userId = action.payload.userId;
        state.token = localStorage.getItem('token');
        state.message = 'Login successful';
      })
      .addCase(login.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload
          ? action.payload.message
          : action.error.message;
        state.message = action.payload
          ? action.payload.message
          : action.error.message;
      });
  },
});

export const { setUser, logout, resetMessage } = loginSlice.actions;
export const selectUserId = (state) => state.login.userId;
export const selectMessage = (state) => state.login.message;
export const selectToken = (state) => state.login.token;
export default loginSlice.reducer;
