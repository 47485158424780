import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { baseUrl } from '../baseUrl';

export const signup = createAsyncThunk(
  'signup/signup',
  async (
    { username, email, phone, password, role, image },
    { rejectWithValue }
  ) => {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);
    formData.append('role', role);
    formData.append('image', image);
    try {
      const response = await axios.post(
        `${baseUrl}api/v1/users/signup`,
        formData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const initialState = {
  username: '',
  email: '',
  phone: '',
  password: '',
  role: 'user',
  image: null,
  status: 'idle',
  error: null,
  signupSuccess: false,
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setImage: (state, action) => {
      state.image = action.payload;
    },
    resetForm: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.status = 'loading';
        state.signupSuccess = false;
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
        state.signupSuccess = true;
      })
      .addCase(signup.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload || 'Failed to sign up';
      });
  },
});

export const selectSignupSuccess = (state) => state.signup.signupSuccess;
export const {
  setUsername,
  setEmail,
  setPhone,
  setPassword,
  setImage,
  resetForm,
} = signupSlice.actions;
export const selectMessage = (state) => state.signup.message;
export default signupSlice.reducer;
