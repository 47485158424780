import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MainPage from '../../Page/Home/MainPage';
import Contacts from '../../Page/Contacts/Contacts';
import Footer from '../../Page/Footer/Footer';

import Loader from '../../Components/Loader/Loader';
import UseImageDropzone from '../ImageDropZone/UseImageDropZone';
import { createCar } from '../../Redux/Cars/createCarSlice';
import Modal from '../../Components/Model/Modal';
import Popup from '../../Components/Button/Popup';

const SellCars = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.getUser.user?.id);
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState('');
  const [popupMessage, setPopupMessage] = useState('');

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [carData, setCarData] = useState({
    carname: '',
    model: '',
    price: '',
    variant: '',
    manual: '',
    kms: '',
    sold: false,
    status: 'Sell',
    images: [],
    owner: userId,
  });

  const handleChange = (e) => {
    setCarData({ ...carData, [e.target.name]: e.target.value });
  };

  const { getRootProps, getInputProps, isDragActive } = UseImageDropzone(
    setShowPopup,
    setPopupType,
    setPopupMessage,
    setCarData
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    Object.keys(carData).forEach((key) => {
      if (key === 'images') {
        carData[key].forEach((file) => {
          formData.append(key, file);
        });
      } else {
        formData.append(key, carData[key]);
      }
    });
    dispatch(createCar(formData))
      .then(() => {
        setModalOpen(true);
        setLoading(true);
        setShowPopup(true);
        setPopupType('success');
        setCarData({
          carname: '',
          model: '',
          price: '',
          variant: '',
          manual: '',
          kms: '',
          images: [],
          owner: userId,
        });
        setTimeout(() => {
          navigate('/');
        }, 3000);
      })
      .catch(() => {
        setLoading(false);
        setShowPopup(true);
        setPopupType('error');
        setModalOpen(false);
      });
  };

  return (
    <>
      <MainPage />
      <div className="pt-52 w-[80%] mx-auto">
        {showPopup && (
          <Popup
            message={popupMessage}
            type={popupType}
            show={showPopup}
            onClose={() => setShowPopup(false)}
          />
        )}
        <p className="font-poetsen text-xl font-semibold pb-5 pt-5">
          Welcome to our <span className="text-red">platform!</span> List your
          vehicle for <span className="text-red">sell</span> here.
        </p>
        {loading && <Loader />}{' '}
        <form
          onSubmit={handleSubmit}
          className="space-y-4 lg:space-y-3 font-poppins font-medium 2xl:space-y-5 xl:h-[80vh] 2xl:h-full xl:pb-10 2xl:pb-2"
        >
          <div
            {...getRootProps()}
            className="p-4 border-2 border-dashed cursor-pointer"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <p>
                Drag 'n' drop up to 5 files here, or click to select only 5
                files
              </p>
            )}
          </div>
          <div className="flex space-x-2 mt-2">
            {carData.images.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Preview ${index}`}
                className="w-16 h-16 object-cover"
              />
            ))}
          </div>
          <input
            name="carname"
            value={carData.carname}
            onChange={handleChange}
            placeholder="Car Name"
            className="w-full p-2 border-2 rounded"
          />
          <input
            name="model"
            value={carData.model}
            onChange={handleChange}
            placeholder="Model"
            className="w-full p-2 border-2 rounded"
          />
          <input
            name="price"
            value={carData.price}
            onChange={handleChange}
            placeholder="Price"
            className="w-full p-2 border-2 rounded"
          />
          <input
            name="variant"
            value={carData.variant}
            onChange={handleChange}
            placeholder="Variant"
            className="w-full p-2 border-2 rounded"
          />
          <select
            name="manual"
            value={carData.manual}
            onChange={handleChange}
            className="w-full p-2 border-2 rounded"
          >
            <option value="">Select Transmission</option>
            <option value={true}>Manual</option>
            <option value={false}>Auto</option>
          </select>
          <input
            name="kms"
            value={carData.kms}
            onChange={handleChange}
            placeholder="Kms"
            className="w-full p-2 border-2 rounded"
          />
          <button
            type="submit"
            className="w-full p-2 bg-blue-500 text-white rounded"
          >
            Sell Car
          </button>
        </form>
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
            <p>Our team will contact you soon.</p>
          </Modal>
        )}
      </div>
      <Contacts />
      <Footer />
    </>
  );
};

export default SellCars;
