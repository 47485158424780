import React from 'react';

import Modal from '../../../Components/Model/Modal';
import Login from '../../../Page/Header/Login';

const LoginAlertModal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose}>
    <div className="flex items-center justify-center ml-5">
      <Login />
    </div>
  </Modal>
);

export default LoginAlertModal;
