import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { baseUrl } from '../../Redux/baseUrl';
import LoaderSmall from '../../Components/Loader/LoaderSmall';

const CarCard = ({ car, onClick }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <motion.div
      key={car.id}
      className="bg-white rounded-lg shadow-md p-6 cursor-pointer transform-gpu"
      onClick={onClick}
      whileHover={{
        scale: 1.05,
        boxShadow: '0px 0px 8px rgb(255, 255, 255)',
      }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full h-64 md:h-80 lg:h-96 overflow-hidden rounded-t-lg mb-4">
        {loading && <LoaderSmall />}
        <img
          src={`${baseUrl}api/v1/cars/${car.id}/images/0`}
          alt={car.name}
          className="w-full h-full object-cover object-center"
          onLoad={handleImageLoad}
        />
      </div>
      <h2 className="text-xl font-bold mb-2 transform-gpu scale-95 hover:scale-100">
        {car.carname}
      </h2>
      <p className="text-gray-700 mb-2 transform-gpu scale-95 hover:scale-100">
        ₹{car.price}
      </p>
      <p className="text-gray-600 text-sm transform-gpu scale-95 hover:scale-100">
        Model: {car.model}
      </p>
    </motion.div>
  );
};

export default CarCard;
