import React from 'react';
import Marquee from 'react-fast-marquee';

import {
  audi,
  benz,
  bmw,
  chevo,
  honda,
  jaguar,
  jeep,
  mg,
  mithshu,
  renult,
  skoda,
  toyo,
  vols,
  volvo,
} from '../../assests/CarsLogo';
import { banner } from '../../assests/Home';
import CAP from '../../assests/Video/CAP.mp4';
import Customers from './Customers';

const Carsimage = () => {
  const carLogos = [
    audi,
    benz,
    bmw,
    chevo,
    honda,
    jaguar,
    jeep,
    mg,
    mithshu,
    renult,
    skoda,
    toyo,
    vols,
    volvo,
  ];

  return (
    <div>
      <div className="py-10">
        <Marquee speed={50}>
          {[...carLogos, ...carLogos].map((logo, index) => (
            <img
              key={index}
              src={logo}
              alt="car logo"
              style={{ width: '100px' }}
            />
          ))}
        </Marquee>
      </div>
      <div className="mt-5">
        <img
          src={banner}
          alt="banner"
          className="object-cover object-center w-full"
        />
      </div>
      <p className="text-center mt-12 text-3xl lg:text-6xl font-poetsen font-semibold mb-10">
        Satisfied <span className="text-red">Clients</span>
      </p>
      <div className="flex justify-center w-full bg-black mt-2 overflow-hidden">
        <video
          src={CAP}
          autoPlay
          loop
          muted
          playsInline
          className="w-auto min-w-full min-h-full max-w-none object-cover object-center my-auto"
          type="video/mp4"
        />
      </div>
      <Customers />
    </div>
  );
};

export default Carsimage;
