import React from 'react';

import { quality } from '../../assests/Home';

const Footer = () => {
  return (
    <div className="">
      <img src={quality} alt="quality" className="w-full mb-12" />
      <div className="fixed bottom-0 left-0 right-0 bg-white text-black text-center py-3 rounded-lg font-poetsen z-50 mt-2 text-xs md:text-sm lg:text-base">
        <span className="text-red md:text-sm text-xs">CAP</span>CARS &copy;{' '}
        {new Date().getFullYear()} Website created by{' '}
        <a
          href="https://ansaribrahim.me/"
          className="text-red text-sm font-poppins font-medium"
        >
          TechTrove
        </a>
      </div>
    </div>
  );
};

export default Footer;
