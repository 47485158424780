import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const UseImageDropzone = (
  setShowPopup,
  setPopupType,
  setPopupMessage,
  setCarData
) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 5) {
        setShowPopup(true);
        setPopupType('error');
        setPopupMessage('Please add only 5 images.');
        return;
      }
      setCarData((prevData) => ({
        ...prevData,
        images: acceptedFiles,
      }));
    },
    [setShowPopup, setPopupType, setPopupMessage, setCarData]
  );

  const onDropRejected = useCallback(
    (fileRejections) => {
      fileRejections.forEach(({ file, errors }) => {
        setShowPopup(true);
        setPopupType('error');
        setPopupMessage(`File ${file.name} was rejected. ${errors[0].message}`);
      });
    },
    [setShowPopup, setPopupType, setPopupMessage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: 'image/png, image/jpeg, image/svg+xml',
  });

  return { getRootProps, getInputProps, isDragActive };
};

export default UseImageDropzone;
