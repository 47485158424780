import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Shared/context/Auth-Context';

import LoginAlertModal from '../Cars/UsedCars/Modal/LoginAlertModal';

const ProtectedComponent = ({ component: Component }) => {
  const { isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      setIsModalOpen(true);
    }
  }, [isLoggedIn]);

  const handleClose = () => {
    setIsModalOpen(false);
    navigate('/');
  };

  if (!isLoggedIn) {
    return <LoginAlertModal isOpen={isModalOpen} onClose={handleClose} />;
  }

  return <Component />;
};

export default ProtectedComponent;
