import React from 'react';

import CarCard from './CarCard';

const CarsList = ({ cars, onCarClick }) => {
  return (
    <>
      <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {cars.map((car) => (
          <div
            key={car.id}
            onClick={() => onCarClick(car)}
            className="cursor-pointer"
          >
            <CarCard car={car} />
          </div>
        ))}
      </div>
    </>
  );
};

export default CarsList;
