import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import SignupForm from '../Components/Form/SignupForm';
import LoginForm from '../Components/Form/LoginForm';
import LoaderSmall from '../../Components/Loader/LoaderSmall';
import Popup from '../../Components/Button/Popup';
import { selectUser } from '../../Redux/Users/getUserSlice';

const Auth = ({ onCloseModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [popup, setPopup] = useState({ show: false, message: '', type: '' });
  const [currentForm, setCurrentForm] = useState('login');
  const user = useSelector(selectUser);

  useEffect(() => {
    setIsLoading(false);
    if (!user) {
      setIsLoading(false);
    }
  }, [user]);

  const handleLoginClick = () => {
    setCurrentForm('login');
  };

  const handleSignupClick = () => {
    setCurrentForm('signup');
  };

  const handleLoginFailure = () => {
    setCurrentForm('signup');
  };

  const handleSignupSuccess = () => {
    setCurrentForm('login');
  };

  return (
    <>
      {isLoading && <LoaderSmall />}
      <div className="bg-zinc-50">
        <motion.div
          className="flex items-center justify-center pt-3 pb-5 flex-col"
          initial="initial"
          animate="animate"
          exit="exit"
        >
          <div className="w-80">
            <div className="flex items-center justify-between mb-4">
              <motion.button
                onClick={handleLoginClick}
                className={`w-1/2 text-center py-2 rounded-t-lg ${
                  currentForm === 'login'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-300 text-gray-500'
                }`}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                Login
              </motion.button>
              <motion.button
                onClick={handleSignupClick}
                className={`w-1/2 text-center py-2 rounded-t-lg ${
                  currentForm === 'signup'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-500'
                }`}
                initial="initial"
                animate="animate"
                exit="exit"
              >
                Sign Up
              </motion.button>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4">
              {currentForm === 'login' ? (
                <LoginForm
                  onCloseModal={onCloseModal}
                  onLoginFailure={handleLoginFailure}
                  setPopup={setPopup}
                />
              ) : (
                <SignupForm
                  onSignupSuccess={handleSignupSuccess}
                  setPopup={setPopup}
                />
              )}
            </div>
          </div>
        </motion.div>
      </div>
      {popup.show && <Popup message={popup.message} type={popup.type} />}
    </>
  );
};

export default Auth;
