import React from 'react';

function Map() {
  return (
    <div
      className="rounded-xl shadow-lg mt-5 mb-5"
      style={{ width: '90%', height: '40vh' }}
    >
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d244.83222036786188!2d76.9518449474036!3d10.939521094372536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba85b30947f86c1%3A0xee41f8184a6614f0!2sCAP%20CARS!5e0!3m2!1sen!2sin!4v1715708342674!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
    </div>
  );
}

export default Map;
