import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { ImLocation } from 'react-icons/im';
import { MdMarkEmailUnread } from 'react-icons/md';
import { IoTime } from 'react-icons/io5';

import Flex from '../../assests/Logo/Flex.png';
import Map from '../../Shared/Map/Map';

const Contacts = () => {
  const date = new Date();
  const day = date.getDay();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const currentTimeInMinutes = hours * 60 + minutes;

  const openingTimeInMinutes = 9 * 60 + 30;
  const closingTimeInMinutes = 20 * 60;

  const isOpen =
    day >= 1 &&
    day <= 6 &&
    currentTimeInMinutes >= openingTimeInMinutes &&
    currentTimeInMinutes < closingTimeInMinutes;
  return (
    <>
      <div
        id="contacts"
        className="flex justify-center flex-col w-full text-xs items-center lg:text-lg pt-5 font-poetsen font-semibold"
      >
        <div className="flex items-center justify-center w-[100%] h-[20%] lg:h-[40%]">
          <Map />
        </div>
        <div className="justify-around flex items-center w-full mb-6 mt-5">
          <div className="flex items-center flex-col text-center">
            <img src={Flex} alt="Flex" className="w-40 pb-2 lg:w-80 ml-4" />
            <hr
              className="
            w-full pt-1 bg-slate-300 rounded-full"
            />
            <div className="flex items-center justify-center flex-col lg:text-3xl text-base pt-1">
              <p>
                PRE OWNED <span className="text-red"> CARS</span>
              </p>
              <p>BUYING | SELLING</p>
              <p>PARK & SELL</p>
            </div>
          </div>
          <div className="flex items-start flex-col justify-start 2xl:text-3xl">
            <div className="flex lg:gap-4 items-center gap-2">
              <FaWhatsapp className="text-red text-base lg:text-2xl" />
              <p>9047113114, 9944123637</p>
            </div>
            <div className="flex lg:gap-4 items-center mt-3 gap-2">
              <MdMarkEmailUnread className="text-red text-base lg:text-2xl" />
              <p>capcarscbe@gmail.com</p>
            </div>
            <div className="flex lg:gap-4 items-center gap-2 mt-3">
              <ImLocation className="text-red text-base lg:text-2xl lg:mb-20 mb-12" />
              <p>
                6/46 Opposite To Krishna <br /> College, Palakad Main Road,{' '}
                <br />
                B.K Pudur, Kuniyamuthur, <br /> Coimbatore - 641 008.
              </p>
            </div>
          </div>
        </div>
        <div className="mb-10 flex flex-col items-center text-center pt-5 2xl:text-3xl">
          <p className="flex items-center gap-2">
            <IoTime className="text-red text-base lg:text-2xl" />
            <span className="text-red">Timings:</span> Mon to Sat: 9:30am to
            8:00pm{' '}
          </p>
          <p>
            Status:{' '}
            <span style={{ color: isOpen ? 'green' : 'red' }}>
              {isOpen ? 'Open' : 'Closed'}
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default Contacts;
