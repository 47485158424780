import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';

import login from '../../assests/login.png';
import defaultImg from './usersignup.jpeg';
import Modal from '../../Components/Model/Modal';
import Auth from '../../Users/Auth/Auth';
import { getUser, resetUser } from '../../Redux/Users/getUserSlice';
import { logout, resetMessage } from '../../Redux/Users/loginSlice';
import { baseUrl } from '../../Redux/baseUrl';
import { AuthContext } from '../../Shared/context/Auth-Context';

const Login = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLogoutSuccess, setLogoutSuccess] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.getUser.user);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (token && userId) {
      dispatch(getUser({ userId, token }));
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [dispatch, setIsLoggedIn]);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleLogin = async () => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (token && userId) {
      try {
        const action = await dispatch(getUser({ userId, token }));
        const userData = unwrapResult(action);

        if (userData) {
          authCtx.login();
          setModalOpen(false);
          setIsLoggedIn(true);
        }
      } catch (err) {
        console.error('Failed to fetch user:', err);
      }
    }
  };

  const handleLogout = () => {
    authCtx.logout();
    dispatch(logout(Date.now()));
    dispatch(resetMessage());
    setIsLoggedIn(false);
    setLogoutSuccess(true);
    setModalOpen(false);
    dispatch(resetUser());
  };

  const renderUserImage = (user) => {
    if (isLoggedIn && user?.username) {
      const imageUrl = `${baseUrl}${
        user.image.startsWith('/') ? user.image.slice(1) : user.image
      }`;

      return imageUrl;
    }

    return defaultImg;
  };

  return (
    <div className="lg:pr-12 pr-4">
      <div
        className="flex flex-col items-center justify-center cursor-pointer"
        onClick={isLoggedIn ? handleLogout : handleOpenModal}
      >
        {isLoggedIn ? (
          user && (
            <img
              src={renderUserImage(user)}
              alt="user"
              className="lg:w-10 lg:h-10 w-6 h-6 rounded-full"
            />
          )
        ) : (
          <img src={login} alt="login" className="lg:w-10 lg:h-10 w-6 h-6" />
        )}

        <p className="lg:text-xl font-poetsen font-semibold text-sm text-red">
          {isLoggedIn ? 'Logout' : 'Login'}
        </p>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <Auth onCloseModal={handleLogin} />{' '}
      </Modal>
      <Modal isOpen={isLogoutSuccess} onClose={() => setLogoutSuccess(false)}>
        <div className="p-6 w-full sm:max-w-md mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
          <div className="flex-shrink-0">
            <svg
              className="h-12 w-12 text-green-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
          <div>
            <div className="text-xl font-medium text-black">Logout Success</div>
            <p className="text-gray-500">You have successfully logged out.</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
