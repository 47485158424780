import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const Card = ({ title, number }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="bg-white rounded-lg shadow-md p-6 font-poetsen">
      <h2 className="text-xl font-bold mb-2">{title}</h2>
      <p className="text-gray-300 text-3xl">
        <CountUp end={inView ? number : 0} />
      </p>
    </div>
  );
};

const Customers = () => {
  const cards = [
    { title: 'Total Customers', number: 1000 },
    { title: 'Vehicle Stock', number: 200 },
    { title: 'Happy Customers', number: 50 },
    { title: 'New Arrivals', number: 30 },
  ];

  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-4 p-5 pt-8">
      {cards.map((card, index) => (
        <Card key={index} title={card.title} number={card.number} />
      ))}
    </div>
  );
};

export default Customers;
