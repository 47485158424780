import React from 'react';
import { NavLink } from 'react-router-dom';
import { FiPhoneCall } from 'react-icons/fi';
import { motion } from 'framer-motion';

import logo from '../../assests/Logo/Flex.png';
import MediaLinks from './MediaLinks';
import Login from './Login';

const Header = () => {
  return (
    <>
      <div className="flex items-center justify-between lg:px-10 bg-slate-50 fixed z-20 w-full lg:p-5 shadow-md px-5 pt-5 pb-2">
        <div className="flex items-center flex-col lg:flex-row lg:justify-around lg:w-[70%]">
          <motion.div
            initial={{ x: '-100vw' }}
            animate={{ x: 0 }}
            transition={{ type: 'spring', stiffness: 120 }}
          >
            <NavLink to={'/'} className="cursor-pointer">
              <img src={logo} alt="logo" className="w-52 pb-1 lg:w-60" />
            </NavLink>
          </motion.div>
          <div className="flex items-center justify-center mt-5 gap-6 lg:gap-16 lg:mt-1">
            <div className="flex items-center gap-2 justify-start">
              <FiPhoneCall className="text-red text-lg lg:text-3xl" />
              <p className="text-red text-xxs md:text-xs lg:text-base font-poppins font-semibold antialiased flex-col items-start justify-start flex">
                Call us
                <span className="md:text-xs lg:text-3xl text-xs font-poetsen font-semibold antialiased text-black">
                  +91 9047120120
                </span>
              </p>
            </div>
            <MediaLinks />
          </div>
        </div>
        <Login />
      </div>
    </>
  );
};

export default Header;
