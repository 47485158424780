import React from 'react';

import './Loader.css';

const LoaderSmall = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="loader"></div>
    </div>
  );
};

export default LoaderSmall;
