import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import company from '../../assests/Home/company.png';

const Company = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  return (
    <div
      className="flex items-center justify-around flex-col mt-10 lg:flex-row mb-10 font-poetsen p-5 overflow-hidden"
      ref={ref}
    >
      <motion.div
        className=""
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: inView ? 0 : -100, opacity: inView ? 1 : 0 }}
        transition={{ duration: 2 }}
      >
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold mb-1">
            Welcome to <span className="text-red">CAP</span> Cars
          </h2>
          <p className="text-lg mb-1">
            <span className="text-red">Pre-Owned Cars</span>
          </p>
          <p className="text-lg">Buying | Selling | Park & Sell</p>
        </div>
        <div className="max-w-lg text-center">
          <p className="mb-4">
            Your trusted destination for quality second-hand cars. At{' '}
            <span className="text-red">CAP</span> Cars, we understand the
            importance of finding the perfect vehicle that fits both your needs
            and budget. With our extensive inventory and commitment to customer
            satisfaction, we strive to make your car-buying experience seamless
            and enjoyable. Explore our collection of reliable pre-owned cars and
            let us help you find your dream ride today.
          </p>
        </div>
      </motion.div>
      <motion.div
        className=""
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: inView ? 0 : 100, opacity: inView ? 1 : 0 }}
        transition={{ duration: 2 }}
      >
        <img src={company} alt="company" className=" w-full h-full mt-6" />
      </motion.div>
    </div>
  );
};

export default Company;
