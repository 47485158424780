import React, { useState, useEffect } from 'react';

import MainPage from './MainPage';
import Intro from '../Intro/Intro';
import Carsimage from '../CarsImage/Carsimage';
import Contacts from '../Contacts/Contacts';
import Footer from '../Footer/Footer';

import LoaderSmall from '../../Components/Loader/LoaderSmall';

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MainPage />
      {loading ? <LoaderSmall /> : <Intro />}
      {loading ? <LoaderSmall /> : <Carsimage />}
      {loading ? <LoaderSmall /> : <Contacts />}
      <Footer />
    </div>
  );
};

export default Home;
