import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';

import SubHeaderLinks from './SubHeaderLinks';
import './css/styles.css';

const SubHeader = () => {
  return (
    <div className="lg:pt-36 lg:pb-4 shadow-md pb-2 lg:text-base fixed w-full z-10 bg-white font-semibold font-poetsen antialiased lg:pl-16 text-xs pt-36 flex items-center">
      <motion.div
        className="pt-4 pb-2 pl-2 lg:pb-2 lg:pl-0 lg:pt-1 overflow-x-auto scrollbar-hide w-[80%] flex items-center mx-auto lg:w-[100%]"
        initial={{ x: 800, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ type: 'spring', stiffness: 100 }}
      >
        <ul className="flex items-center md:gap-5 gap-2 text-xs md:text-sm text-center font-inter whitespace-nowrap">
          {SubHeaderLinks.map((link, index) =>
            link.isNavLink ? (
              <NavLink
                key={index}
                to={link.path}
                className={({ isActive }) =>
                  `border-r px-2 lg:px-4 ${isActive ? 'text-red' : ''}`
                }
              >
                {link.name}
              </NavLink>
            ) : (
              <a key={index} href={link.path} className="border-r px-2 lg:px-4">
                {link.name}
              </a>
            )
          )}
        </ul>
      </motion.div>
    </div>
  );
};

export default SubHeader;
