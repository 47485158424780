import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AuthContext } from '../../Shared/context/Auth-Context';
import CarsList from './CarsList';
import CarDetailsModal from './Modal/CarDetailsModal';
import LoginAlertModal from './Modal/LoginAlertModal';
import { fetchCars, setRole } from '../../Redux/Cars/carSlice';
import MainPage from '../../Page/Home/MainPage';
import Contacts from '../../Page/Contacts/Contacts';
import Footer from '../../Page/Footer/Footer';

const UsedCars = () => {
  const authCtx = useContext(AuthContext);
  const [selectedCar, setSelectedCar] = useState(null);
  const [showLoginAlert, setShowLoginAlert] = useState(false);

  const dispatch = useDispatch();
  const cars = useSelector((state) => state.cars.cars);

  useEffect(() => {
    dispatch(fetchCars());
    dispatch(setRole('admin'));

    window.scrollTo(0, 0);
  }, [dispatch]);

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      setShowLoginAlert(false);
    }
    if (!authCtx.isLoggedIn) {
      setSelectedCar(null);
    }
  }, [authCtx.isLoggedIn]);

  const handleCardClick = (car) => {
    if (authCtx.isLoggedIn) {
      setSelectedCar(car);
    } else {
      setShowLoginAlert(true);
    }
  };

  const handleCloseModal = () => {
    setSelectedCar(null);
    setShowLoginAlert(false);
  };

  return (
    <>
      <MainPage />
      <>
        <div className="pt-56">
          <p className="text-center font-poetsen lg:text-2xl font-semibold text-sm pb-5">
            Automobile <span className="text-red">Model Selection</span>
          </p>
          <div className="px-3 lg:pb-20 border rounded-xl shadow-lg w-[80%] mx-auto pt-5">
            <CarsList cars={cars} onCarClick={handleCardClick} />
          </div>
        </div>
        <div className="w-[80%]">
          {selectedCar && (
            <CarDetailsModal
              isOpen={!!selectedCar}
              onClose={handleCloseModal}
              car={selectedCar}
            />
          )}
        </div>
        {showLoginAlert && (
          <LoginAlertModal isOpen={showLoginAlert} onClose={handleCloseModal} />
        )}
      </>
      <Contacts />
      <Footer />
    </>
  );
};

export default UsedCars;
