import React from 'react';
import { Routes, Route } from 'react-router-dom';

import UsedCars from '../Cars/UsedCars/UsedCars';
import SellCars from '../Cars/SellCars/SellCars';
import ParkSell from '../Cars/Park&Sell/ParkSell';
import Home from '../Page/Home/Home';
import ProtectedComponent from './ProductedRoute';

const PageRoute = () => {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/buy-cars" element={<UsedCars />} />
        <Route
          path="/sell-cars"
          element={<ProtectedComponent component={SellCars} />}
        />
        <Route
          path="/park-and-sell"
          element={<ProtectedComponent component={ParkSell} />}
        />
      </Routes>
    </div>
  );
};

export default PageRoute;
