import React from 'react';

import Header from '../Header/Header';
import SubHeader from '../Header/SubHeader';

const MainPage = () => {
  return (
    <div>
      <Header />
      <SubHeader />
    </div>
  );
};

export default MainPage;
