import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { baseUrl } from '../baseUrl';

export const getUser = createAsyncThunk(
  'getUser',
  async ({ userId, token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${baseUrl}api/v1/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const getUserSlice = createSlice({
  name: 'getUser',
  initialState: {
    user: null,
    status: 'idle',
    error: null,
  },
  reducers: {
    resetUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.status = 'idle';
        state.error = action.payload;
      });
  },
});

export const { resetUser } = getUserSlice.actions;
export const selectUser = (state) => state.getUser.user;

export default getUserSlice.reducer;
