import React from 'react';

import {
  instagram,
  youtube,
  faceBook,
  whatsapp,
} from '../../assests/SocialMediaLinks';

const MediaLinks = () => {
  return (
    <div className="flex items-center gap-2 text-center mt-1 lg:mt-6">
      <a
        href="https://www.instagram.com/cap_cars_cbe?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={instagram}
          alt="instagram"
          className="w-5 h-5 rounded-full cursor-pointer lg:w-8 lg:h-8"
        />
      </a>
      <a
        href="https://youtube.com/@capcarscoimbatore?si=aPGeL5IWRQeZ40fA"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={youtube}
          alt="youtube"
          className="w-5 h-5 rounded-full cursor-pointer lg:w-8 lg:h-8"
        />
      </a>
      <a
        href="https://www.facebook.com/profile.php?id=61552316053730&mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={faceBook}
          alt="facebook"
          className="w-5 h-5 rounded-full cursor-pointer lg:w-8 lg:h-8"
        />
      </a>
      <a
        href="https://wa.me/919047120120"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={whatsapp}
          alt="whatsapp"
          className="w-5 h-5 cursor-pointer lg:w-8 lg:h-8"
        />
      </a>
    </div>
  );
};

export default MediaLinks;
