import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  signup,
  setUsername,
  setEmail,
  setPhone,
  setPassword,
  setImage,
  resetForm,
} from '../../../Redux/Users/signupSlice';

const SignupForm = ({ onSignupSuccess, setPopup }) => {
  const dispatch = useDispatch();
  const username = useSelector((state) => state.signup.username);
  const email = useSelector((state) => state.signup.email);
  const phone = useSelector((state) => state.signup.phone);
  const password = useSelector((state) => state.signup.password);
  const image = useSelector((state) => state.signup.image);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!phone || phone.length < 10) {
      setPopup({
        show: true,
        message: 'Please enter a valid phone number.',
        type: 'error',
      });
      return;
    }
    dispatch(
      signup({ username, email, phone, password, role: 'user', image })
    ).then((action) => {
      if (!action.error) {
        onSignupSuccess();
        dispatch(resetForm());
      } else {
        setPopup({
          show: true,
          message: 'Please enter a valid phone number.',
          type: 'error',
        });
      }
    });
  };

  const handleFileChange = (e) => {
    dispatch(setImage(e.target.files[0]));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="space-y-6 overflow-y-scroll h-64"
      >
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            value={username}
            onChange={(e) => dispatch(setUsername(e.target.value))}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => dispatch(setEmail(e.target.value))}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Phone
          </label>
          <input
            type="number"
            value={phone}
            onChange={(e) => dispatch(setPhone(e.target.value))}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Password
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => dispatch(setPassword(e.target.value))}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="flex items-center space-x-3">
          <label
            htmlFor="image"
            className="cursor-pointer text-white bg-blue-500 rounded-md px-4 py-2 transition duration-500 ease select-none hover:bg-blue-600 focus:outline-none focus:shadow-outline"
          >
            Upload Image
          </label>
          <input
            id="image"
            type="file"
            onChange={handleFileChange}
            className="hidden"
          />
          {image && <span className="text-sm">{image.name}</span>}
        </div>
        <div className="pb-5">
          <button
            type="submit"
            className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Sign Up
          </button>
        </div>
      </form>
    </>
  );
};

export default SignupForm;
