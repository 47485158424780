const SubHeaderLinks = [
  {
    name: 'Home',
    path: '/',
    isNavLink: true,
  },
  {
    name: 'BUY CARS',
    path: '/buy-cars',
    isNavLink: true,
  },
  {
    name: 'SELL CARS',
    path: '/sell-cars',
    isNavLink: true,
  },
  {
    name: 'PARK & SELL',
    path: '/park-and-sell',
    isNavLink: true,
  },
  {
    name: 'CONTACTS',
    path: '#contacts',
    isNavLink: false,
  },
];

export default SubHeaderLinks;
