import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { baseUrl } from '../baseUrl';

export const createCar = createAsyncThunk(
  'cars/createCar',
  async (carData, { rejectWithValue }) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(`${baseUrl}api/v1/cars`, carData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const createCarSlice = createSlice({
  name: 'cars',
  initialState: {
    car: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCar.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCar.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.car = action.payload;
      })
      .addCase(createCar.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default createCarSlice.reducer;
